

















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";

@Component
export default class UserPresentation extends Vue {

  @Prop() image!: String;
  @Prop() name!: String;
  @Prop() description!: String;
  @Prop() onNameClick!: Function;


  get nameSymbol(): String{
    return this.name?.slice(0,1);
  }
}
