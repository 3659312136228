import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const templatesModule = () => import('./containers/templatesModule.vue');
export const templatesRouting: Array<RouteConfig>  = [
    {
        path: 'templates',
        name: 'templates',
        component: templatesModule,
        children: [
            {
                path: ':id',
                name: 'template',
                children: [],
            },
            {
                path: ':id/preview',
                name: 'template-preview',
                children: [],
            }
        ],
    },
];
