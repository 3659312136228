import {outsideEnterGuard} from "@/shared/guards";
import {NavigationGuard, NavigationGuardNext, Route, RouteConfig} from "vue-router";
import store from "@/store/rootStore";
import rootStore from "@/store/rootStore";
import Vue from 'vue';

const subscriptionModule = () => import('./containers/SubscriptionModule.vue');

export const subscriptionsRouting: Array<RouteConfig>  = [
    {
        path: 'capital-rise/new-subscription',
        name: 'newSubscription',
        props: () => ({ viewType: 'add' }),
        component: subscriptionModule,
    },
    {
      path: 'capital-rise/subscriptions/:id',
      name: 'editSubscription',
      props: () => ({ viewType: 'edit' }),
      component: subscriptionModule,
    },
];
