import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const reportingModule = () => import('./containers/reportingModule.vue');
const reportingBlindModule = () => import('./containers/reportingBlindModule.vue');

export const reportingRouting : Array<RouteConfig> = [
    {
        path: 'reporting',  /* /fund /company */
        name: 'reporting',
        component: reportingModule,
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData'];
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData');
            }

            const isPortfolioCompany = to.path.includes('company') ? true : false;
            if(!isPortfolioCompany) {
                if(activeUserData?.context?.context == 'fund') {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else {
                    const viewedFund = await store.dispatch('auth/getViewedFund');
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedFund);

                    if(leAccessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                }
            } else {
                if(activeUserData?.context?.context == 'company') {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else {
                    const viewedCompany = await store.dispatch('auth/getViewedCompany');
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedCompany);
                    
                    if(leAccessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                }
            }
        },
    },
    {
        path: 'reporting/access-denied',
        name: 'reporting-access-denied',
        component: reportingBlindModule
    }
];
