





















































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInputGroup from '@/components/inputs/SygniInputGroup.vue';
import SygniDetails from '@/components/layout/SygniDetails.vue';
import FileUploader from "@/components/FileUploader.vue";
import PortfolioTransactionsTable from './PortfolioTransactionsTable.vue'
import { BSpinner } from 'bootstrap-vue';
import { FileItem, Transaction } from '../store/types';
import Utils from '@/modules/shared/utils/utils';
import { BACKEND_BASE_URL } from '@/shared/consts';

@Component({
  components: { SygniLinkButton, SygniRoundedButton, SygniFileBox, SygniRectButton, SygniInputGroup, SygniContainerTitle, SygniDetails, FileUploader, PortfolioTransactionsTable, BSpinner },
})
export default class PortfolioModal extends Vue {
  @Prop() isLoading!: boolean;
  @Prop({default: false}) showNavigation: boolean;
  @Prop() transactionId: string;

  details: Array<any> = [
    {
      label: 'CODE',
      value: ''
    },
    {
      label: 'DATE',
      value: ''
    },
    {
      label: 'STATUS',
      value: ''
    },
    {
      label: 'REGISTERED',
      value: ''
    },
  ]

  files: Array<FileItem> = [];

  items: Array<any>;

  closeModal() {
    this.$emit('close');
  }

  downloadFile(file: FileItem) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.file}`, file.fileName);
  }

  prevFn() {
    this.$emit('prev');
  }

  nextFn() {
    this.$emit('next');
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('company') ? true : false;
  }

  loadTransactionData(transaction: Transaction): void {
    this.details[0].value = transaction.code;
    this.details[1].value = this.$options.filters.dateWithoutTime(transaction.date);
    this.details[2].value = transaction.registrationStatus.charAt(0).toUpperCase() + transaction.registrationStatus.slice(1);
    this.details[3].value = this.$options.filters.dateWithoutTime(transaction.registrationDate);
    this.files = transaction.files;
    this.items = transaction.items;
  }

  async loadTransaction(id: String): Promise<void> {
    try {
      const transaction = await this.$store.dispatch('transactions/getTransactionById', {
        id: id,
        viewedContext: this.viewedId,
      })
      this.loadTransactionData(transaction);
      this.$emit('loaded');
    } catch(error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }
  }

  @Watch('transactionId') onTransactionChange() {
    if(this.transactionId) {
      this.loadTransaction(this.transactionId);
    }
  }
}
