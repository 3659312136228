import {BACKEND_BASE_URL} from "@/shared/consts";
import {RouteConfig} from "vue-router";
import {accountingRouting} from "@/modules/accounting/accounting.routing";
import {capitalRiseRouting} from "@/modules/genprox/modules/fund/modules/capital-rise/capitalRise.routing";
import {capitalRouting} from "@/modules/genprox/modules/fund/modules/capital/capital.routing";
import { whistleBlowerRouting } from "@/modules/genprox/modules/whistleblower/whistleblower.routing";
import { transactionsRouting } from "../fund/modules/transactions/transactions.routing";
import { productsRouting } from "../fund/modules/capital-rise/modules/products/products.routing";
import { advisorsRouting } from "@/modules/genprox/modules/fund/modules/capital-rise/modules/advisors/advisors.routing";
import { reportingRouting } from "../reporting/reporting.routing";
import { templatesRouting } from "@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/templates.routing"
import { statementsRouting } from "@/modules/statements/statements.routing"
const companyModule = () => import('./containers/CompanyModule.vue');
const CompanyDashboard = () => import('./containers/CompanyDashboard.vue');
const pivotContainer = () => import('@/modules/genprox/containers/PivotContainer.vue');

export const companyRouting: Array<RouteConfig> = [
    {
        path: 'company',
        name: 'portfolio-company',
        alias: 'portfolio-company',
        component: companyModule,
        children: [
            ...accountingRouting,
            ...whistleBlowerRouting,
            ...capitalRouting,
            ...transactionsRouting,
            ...productsRouting,
            ...reportingRouting,
            ...capitalRiseRouting,
            ...advisorsRouting,
            ...templatesRouting,
            ...statementsRouting,
            {
                path: 'dashboard',
                component: CompanyDashboard,
                name: 'company-dashboard',
            },
            {
                path: 'analytics',
                name: 'company-analytics',
                component: pivotContainer,
            },
        ]
    },
];
