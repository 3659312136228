import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL, BACKEND_BASE_URL} from "@/shared/consts";
import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { TableData } from "@/modules/genprox/modules/profile/store/types";
import { Sorting, TableQuery } from "@/modules/shared/utils/TableQuery";
import { Dictionaries, PortfolioState } from "./types";

// export interface PortfolioState {
//     portfolioTableIsBusy: boolean,
//     portfolioTable: TableData<PortfolioItem>,
//     dictionaries: Dictionaries,
// }

export const state: PortfolioState = {
    portfolioTableIsBusy: false,
    portfolioTable: {
        items: [],
        totalCount: 0,
        perPage: 10,
        query: new TableQuery(10),
    },
    dictionaries: null
}

export const getters: GetterTree<PortfolioState, RootState> = {
    getPortfolioTableItems(state) {
        return JSON.parse(JSON.stringify(state.portfolioTable.items));
    },
    getPortfolioTableQuery(state) {
        return JSON.parse(JSON.stringify(state.portfolioTable.query));
    },
    getPortfolioTableFiltersQuery(state) {
        return JSON.parse(JSON.stringify(state.portfolioTable.query.filters));
    },
    getPortfolioTablePerPage(state) {
        return JSON.parse(JSON.stringify(state.portfolioTable.perPage));
    },
    getDictionaries(state) {
        return JSON.parse(JSON.stringify(state.dictionaries));
    }
}

export const mutations: MutationTree<PortfolioState> = {
    setPortfolioTableItems(state, payload: {items: Array<any>, totalCount: number}): void {
        state.portfolioTable = { ...state.portfolioTable, items: payload.items, totalCount: payload.totalCount};
    },
    setPortfolioTableQuery(state, payload: TableQuery): void {
        state.portfolioTable.query = payload;
    },
    setPortfolioTableBusy(state,payload: boolean): void {
        state.portfolioTableIsBusy = payload;
    },
    setPortfolioTablePerPage(state, payload: number): void {
        state.portfolioTable.perPage = payload;
    },
    setPortfolioTableFiltersQuery(state, payload: string) {
        state.portfolioTable.query.filters = payload;
    },
    setPortfolioTableSortingQuery(state, payload: Sorting): void {
        state.portfolioTable.query.sorting = payload;
        state.portfolioTable.query.offset = 0;
    },
    setDictionaries(state, payload: Dictionaries): void {
        state.dictionaries = payload;
    }
}

export const actions: ActionTree<PortfolioState, RootState> = {
    async getPortfolioByCompany({state,commit}, payload: { filtersQuery?: string, viewedContext?: string }): Promise<Array<any>> {
        const queryString = state.portfolioTable.query.getStringQuery();
        payload.filtersQuery = payload.filtersQuery ? payload.filtersQuery : '';

        const headers: any = {
            'x-total-count': true
        }

        if(payload.viewedContext) {
            headers['Viewed-Context'] = payload.viewedContext;
        }

        const response = await axios.get(`${BACKEND_API_URL}/portfolio/by-company${queryString}${payload.filtersQuery}`, { headers: {
            'x-total-count': true,
            'Viewed-Context': payload.viewedContext
        } });

        const data: any = { items: response.data.items, totalCount: response.headers['x-total-count'] }

        commit('setPortfolioTableItems', {
            items: data.items,
            totalCount: data.totalCount,
        });

        return data.items;
    },
    async getPortfolioByFund({state,commit}, payload: { filtersQuery?: string, viewedContext?: string }): Promise<Array<any>> {
        const queryString = state.portfolioTable.query.getStringQuery();
        payload.filtersQuery = payload.filtersQuery ? payload.filtersQuery : '';
        const response = await axios.get(`${BACKEND_API_URL}/portfolio/by-fund${queryString}${payload.filtersQuery}`, { headers: { 'x-total-count': true} });

        const data: any = { items: response.data.items, totalCount: response.headers['x-total-count'] }

        commit('setPortfolioTableItems', {
            items: data.items,
            totalCount: data.totalCount,
        });

        return data.items;
    },
    async getPortfolioTransactionsByCompanyId({state,commit}, id: string): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/portfolio/by-company/${id}/transactions`, { headers: {
            'x-total-count': true,
        } });

        return response.data;
    },
    async getPortfolioTransactionsByCompanyIdWithContext({state,commit}, payload: { id: string, viewedContext: string }): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/portfolio/by-company/${payload.id}/transactions`, { headers: {
            'x-total-count': true,
            'Viewed-Context': payload.viewedContext
        } });

        return response.data;
    },
    async getPortfolioTransactionsByFundId({state,commit}, id: string): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/portfolio/by-fund/${id}/transactions`, { headers: { 'x-total-count': true} });

        return response.data;
    },
    async getTransactionById({state,commit}, id: string): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/transaction/${id}`);

        return response.data;
    },
    async getTransactionByIdWithContext({state,commit}, payload: { id: string, viewedContext: string }): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/transaction/${payload.id}`, { headers: { 'Viewed-Context': payload.viewedContext } });

        return response.data;
    },
    async getDictionaries({state,commit}, viewedContext: string): Promise<Array<any>> {
        const response = await axios.get(`${BACKEND_API_URL}/portfolio/dictionaries`, { headers: {
            'x-total-count': true,
            'Viewed-Context': viewedContext
        }});
        commit('setDictionaries', response.data);

        return response.data;
    }
}

export const portfolio: Module<PortfolioState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
